import Swiper from "swiper";

export const SWIPER_CLASS = "rank-swiper"

export function initRankSwiper() {
  document.querySelectorAll(`.${SWIPER_CLASS}`).forEach((e) => {
    if (!e.swiper && e.offsetWidth !== 0) {
      new Swiper(e, {
        slidesPerView: 1,
        spaceBetween: 8
      });
    } else {
      e.swiper?.update();
    }
  });
}

onload(() => {
  initRankSwiper();
});